import yup from 'lib/yupLocalised'
import { LOGIN_CODE_LENGTH } from 'lib/constants'

export const emailSchema = yup.object().shape({
  email: yup.string().email().required(),
})

export const loginCodeSchema = yup.object().shape({
  loginCode: yup
    .string()
    .length(LOGIN_CODE_LENGTH, { id: 'clientLogin.loginCode.validation.incorrectCode' })
    .required(),
})
