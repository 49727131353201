import { Field, Form, useFormikContext } from 'formik'
import { prop } from 'ramda'
import { FormattedMessage } from 'react-intl'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { ALERT_KINDS } from 'lib/constants'
import { setClientEmail } from 'state/concepts/login/actions'
import Alert from 'views/shared/Alert'
import InputField from 'views/shared/InputField'
import SubmitFormButton from 'views/shared/SubmitFormButton'

const FormContent = () => {
  const handleClearEmail = useDispatchAction(setClientEmail, null)
  const { status, isSubmitting } = useFormikContext()

  return (
    <Form>
      <Field
        name="email"
        component={InputField}
        id="email"
        label={{ id: 'clientLogin.emailAddress' }}
        icon="alert"
        allowClear
        onClear={handleClearEmail}
        disabled
        className="mt-16"
        data-cy="email-input"
      />
      <p className="text-subheader in-blue-gray-300">
        <FormattedMessage id="clientLogin.loginCodeDescription" />
      </p>
      {prop('base', status) && (
        <Alert type={ALERT_KINDS.error} className="auth__alert" message={prop('base', status)} />
      )}
      <Field
        name="loginCode"
        component={InputField}
        id="loginCode"
        label={{ id: 'clientLogin.loginCode' }}
        placeholder={{ id: 'clientLogin.loginCode.placeholder' }}
        icon="alert"
        autoComplete="off"
        className="mt-16"
        data-cy="login-code-input"
      />
      <SubmitFormButton
        text={{ id: 'clientLogin.submitCode' }}
        isFullWidth
        size="medium"
        disabled={isSubmitting}
        dataCy="submit-login-code"
      />
    </Form>
  )
}

export default FormContent
