import Image from 'next/legacy/image'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useMount } from 'react-use'

import isPresent from 'utils/isPresent'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { loginRoute } from 'lib/routes'
import { validateConfirmationToken } from 'state/concepts/client/actions'
import { clientEmailSelector, contactPortalNameSelector } from 'state/concepts/login/selectors'
import { isClientPortalEnabledSelector, workspaceSelector } from 'state/concepts/subdomain/selectors'
import redirect from 'utils/redirect'
import CleanLayout from 'views/layouts/Clean'
import AuthTermsAndConditionsFooter from 'views/shared/AuthTermsAndConditionsFooter'
import Link from 'views/shared/Link'
import CodeForm from './CodeForm'
import EmailForm from './EmailForm'

const ClientLogin = ({ token }) => {
  const { logoUrl, name } = useSelector(workspaceSelector)
  const email = useSelector(clientEmailSelector)
  const handleValidateConfirmationToken = useDispatchAction(validateConfirmationToken)
  const contactPortalName = useSelector(contactPortalNameSelector)

  useMount(() => {
    if (token) handleValidateConfirmationToken(token)
  })

  return (
    <>
      <div className="auth__inner subcontainer">
        <div className="login__content mb-40">
          <div className="d-flex align-items-center mb-32">
            {logoUrl && (
              <Image src={logoUrl} alt="Workspace logo" className="border-radius-wrap" width={32} height={32} />
            )}
            <div
              className="ml-12 in-blue-gray-800 font-600 company-name-wrap company-name-break"
              data-cy="client-login-company-name"
            >
              {name}
            </div>
          </div>
          <p className="font-600 text-headline mb-8 company-name-break" data-cy="client-login-title">
            {isPresent(contactPortalName) ? (
              <FormattedMessage id="clientLogin.loginToContactPortal" values={{ name: contactPortalName }} />
            ) : (
              <FormattedMessage id="clientLogin.loginToPortal" values={{ name }} />
            )}
          </p>
          <p className="text-subheader in-blue-gray-300" data-cy="client-login-description">
            <FormattedMessage id="clientLogin.loginDescription" />
          </p>
          {email ? <CodeForm email={email} /> : <EmailForm />}
        </div>
        <p className="text-subheader text-center mb-24">
          <span className="in-blue-gray-800 mr-8" data-cy="expert-login-title">
            <FormattedMessage id="clientLogin.isExpertLogin" />
          </span>
          <Link href={loginRoute} isRoot>
            <a className="main-link tight-font-family font-600" data-cy="expert-login-link">
              <FormattedMessage id="login.logInHere" />
            </a>
          </Link>
        </p>
      </div>
      <AuthTermsAndConditionsFooter />
    </>
  )
}

ClientLogin.getLayout = page => <CleanLayout>{page}</CleanLayout>

ClientLogin.pageTitle = { id: 'pageTitle.client.login' }

ClientLogin.propTypes = {
  token: PropTypes.string,
}

ClientLogin.defaultProps = {
  token: undefined,
}

ClientLogin.getInitialProps = ({ query: { token }, store, res }) => {
  const isClientPortalEnabled = isClientPortalEnabledSelector(store.getState())

  if (!isClientPortalEnabled) {
    redirect({ href: loginRoute, response: res })
  }

  return { token }
}

export default ClientLogin
