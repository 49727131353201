import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { loginCodeSchema } from 'lib/yupLocalised/schemas/clientLogin'
import { clientLogin, clientResendCode } from 'state/concepts/session/actions'
import { currentWorkspaceCustomDomainSelector } from 'state/concepts/session/selectors'
import { isStatusConnected } from 'utils/customDomains'
import Recaptcha from 'views/shared/Recaptcha'
import FormContent from './FormContent'

const CodeForm = ({ email }) => {
  const handleSubmit = useFormSubmit(clientLogin)
  const handleResend = useDispatchAction(clientResendCode, email)
  const customDomain = useSelector(currentWorkspaceCustomDomainSelector)
  const isCaptchaEnabled = !isStatusConnected(customDomain?.status)

  return (
    <>
      <Formik initialValues={{ email, loginCode: '' }} validationSchema={loginCodeSchema} onSubmit={handleSubmit}>
        {isCaptchaEnabled ? (
          <Recaptcha action="clientLogin">
            <FormContent />
          </Recaptcha>
        ) : (
          <FormContent />
        )}
      </Formik>

      <p className="text-subheader mt-32 mb-0">
        <span className="in-blue-gray-800 mr-8">
          <FormattedMessage id="clientLogin.didNotGetCode" />
        </span>
        <button className="main-link tight-font-family font-600" onClick={handleResend} data-cy="resend-login-code">
          <FormattedMessage id="clientLogin.resendCode" />
        </button>
      </p>
    </>
  )
}

CodeForm.propTypes = {
  email: PropTypes.string.isRequired,
}

export default CodeForm
