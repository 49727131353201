import { useUnmount } from 'react-use'
import { useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { prop } from 'ramda'

import { ALERT_KINDS } from 'lib/constants'
import { emailSchema } from 'lib/yupLocalised/schemas/clientLogin'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { clientSendCode } from 'state/concepts/session/actions'
import { setIsLoggedOutDueToInactivity } from 'state/concepts/login/actions'
import { isLoggedOutDueToInactivitySelector } from 'state/concepts/login/selectors'
import Alert from 'views/shared/Alert'
import InputField from 'views/shared/InputField'
import SubmitFormButton from 'views/shared/SubmitFormButton'

const EmailForm = () => {
  const isLoggedOutDueToInactivity = useSelector(isLoggedOutDueToInactivitySelector)
  const handleSubmit = useFormSubmit(clientSendCode)
  const initialStatus = isLoggedOutDueToInactivity ? { base: { id: 'notifications.automaticLogoutProcessed' } } : {}
  const unsetIsLoggedOutDueToInactivity = useDispatchAction(setIsLoggedOutDueToInactivity, false)

  useUnmount(unsetIsLoggedOutDueToInactivity)

  return (
    <Formik
      initialValues={{ email: '' }}
      initialStatus={initialStatus}
      validationSchema={emailSchema}
      onSubmit={handleSubmit}
    >
      {({ status, isSubmitting }) => (
        <Form>
          {prop('base', status) && (
            <Alert type={ALERT_KINDS.error} className="auth__alert" message={prop('base', status)} />
          )}

          <Field
            name="email"
            component={InputField}
            id="email"
            label={{ id: 'clientLogin.emailAddress' }}
            icon="alert"
            autoComplete="off"
            data-cy="email-input"
          />
          <SubmitFormButton
            text={{ id: 'clientLogin.submitEmail' }}
            isFullWidth
            size="medium"
            disabled={isSubmitting}
            dataCy="submit-email"
          />
        </Form>
      )}
    </Formik>
  )
}

export default EmailForm
